body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/** Layout */

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  position: relative;
}

/** User login */
.login-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-form-button {
  width: 100%;
}
.login-presentation {
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(87, 87, 87, 0.53);
  -moz-box-shadow: 0px 0px 10px 1px rgba(87, 87, 87, 0.53);
  box-shadow: 0px 0px 10px 1px rgba(87, 87, 87, 0.53);
}
.login-presentation .ant-image {
  width: fit-content !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.login-presentation .ant-image-img {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.right-form {
  padding: 25px;
}
.custom-spin {
  text-align: center;
}
